import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeDataState, changeModalState } from "../Store/action";
import { urls, dec, returningValue, getList, notify, save, basefileURL, getUserfromSS, getfList, enc, listType, buttonColor, encryptData, handleDownload } from "./Config";
import jwtDecode from 'jwt-decode';
import { FiEdit } from "react-icons/fi";
import { FaRegHospital, FaStethoscope } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { RxAvatar } from "react-icons/rx";
import { TbCameraPlus } from "react-icons/tb"
import Modal from "react-bootstrap/Modal";
import { MdLockOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import { PiCirclesThreeFill } from "react-icons/pi";
import { CiStickyNote } from "react-icons/ci";
import ManageNotes from "../Components/PracticeManagement/Main/Requests/ManageNotes";
import { MdCancel } from "react-icons/md";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";

// import { useTheme } from "../Teme/ThemeProvider";

export const ConfigFunctions = () => {
    // const { theme, toggleTheme } = useTheme();
    const n = useNavigate()
    const dispatch = useDispatch()
    const state = useSelector(s => s)
    const modal = useSelector(s => s?.modal);
    const update = useSelector(s => s?.update);
    const navigate = (url) => n(url)
    const [updateS, setUpdate] = useState()
    const checkLoginStatus = () => {
        let token = sessionStorage.getItem("token") || state?.token

        let decodedData = returningValue(token, "") != '' ? jwtDecode(sessionStorage.getItem("token")) : {};

        let user = JSON.parse(dec(sessionStorage.getItem("user") || state?.user) || "{}")

        let loginStatus = (Object.keys(decodedData)?.length > 0 && decodedData?.UserId?.[0] == user?.userId) || false
        return loginStatus
    }

    const uploadPic = async (id, file, type) => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("Image", file);
        let res = await save(urls?.[type]?.uploadImage, formData);
        if (res?.data?.status == true) {
            callorUpdateDataList([{ type: type, update: true }], [])
        }
    };

    const changeHandler = (id, type) => (e) => {
        e.preventDefault();
        let res = {}
        const file = e.target.files[0];
        const fileType = file["type"];
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        if (!validImageTypes.includes(fileType)) {
            notify(false, "Please select image only!!!");
        } else {
            res = uploadPic(id, file, type);
        }
        return res
    };

    const updateStatus = async (row, status) => {
        //REquest update
        let res = await save(urls.Request.Save, { requestId: row?.requestId, status: status })
        if (res?.data?.status == true) callorUpdateDataList([
            {
                type: listType.request,
                update: update,
                url: urls?.Request?.getAll,
                body: {},
            },
        ]);
    }

    //Change Modal is nothing but opening and closing the modal
    //additionally we can update or call the data list by simply calling callorUpdateDataList
    //This is the sample data
    // addProduct: false,
    // based on the key we will open or close Modal

    // keys: [{ type: "Product", update: true }],
    // clearData: [{ type: "Module" }]
    //These 2 are already explained in callorUpdateDataList function
    const changeModal = (data) => {
        if ([data?.keys, data?.clearData]?.some(e => returningValue(e, "Array")?.length > 0)) {
            callorUpdateDataList(data?.keys, data?.clearData)
        }
        dispatch(changeModalState(data))
    }

    //This is the one fetching the list of the apis and storing in the Store
    //here we are taking 2 inputs 
    // one is keys and another is clearData 
    //Sample data will show


    // keys: [{ type: "Product", update: true ,body:body}]

    //Keys is an array containing of type,update and body
    //type is basiccally define dataList type and it will used in returnUrl function
    //body is nothing but api body if we have anything
    //update will tell wether the api call is calling first time or updating
    // if we pass update:true irrespective of isLoaded it will call the api and update the list
    // if we didn't pass or if we pass update:false api will not call if it already loaded

    // clearData:  [{ type: "Module" }]
    //clearData  is an array containing of type
    //this will help you to clear the dependent data
    //ex:we have Product and Module 
    //say module is dependent on Product
    //If we Update any of the item in Product Module should update 
    //In that case we can update Both List or we can update Product and remove Module
    //If we have one or two dependencies we can directly update but if we have more dependencies 
    //instead of updating clearing the dependencies is best

    // const callorUpdateDataList = async (keys, clearData = []) => {
    //     if (returningValue(keys, "Array")?.length > 0) {
    //         keys?.map(async (e) => {
    //             if (e?.update == true || (!returningValue(state?.[e?.type]?.isLoaded, "Bool"))) {
    //                 let res = []
    //                 if (e?.method == "Get") res = await getfList(e?.url);
    //                 else res = await getList(e?.url, e?.body || {});
    //                 dispatch(changeDataState({ [e?.type]: { data: res, isLoaded: true } }));
    //             }
    //         })
    //     }
    //     if (returningValue(clearData, "Array")?.length > 0) {
    //         let temp = {}
    //         clearData?.map((e) => {
    //             temp[e?.type] = { data: [], isLoaded: false }
    //         })
    //         dispatch(changeDataState(temp));
    //     }
    // }

    const callorUpdateDataList = async (keys, clearData = []) => {
        if (returningValue(keys, "Array")?.length > 0) {
            keys?.map(async (e) => {
                if (e?.update == true || (!returningValue(state?.[e?.type]?.isLoaded, "Bool")) || e?.update == update) {
                    let res = await getList(e?.url, e?.body || {});
                    dispatch(changeDataState({ [e?.type]: { data: res, isLoaded: true, loadedAt: Date.now() } }));
                }
            })
        }
        if (returningValue(clearData, "Array")?.length > 0) {
            let temp = {}
            clearData?.map((e) => {
                temp[e?.type] = { data: [], isLoaded: false }
            })
            dispatch(changeDataState(temp));
        }
    }

    const requestKeys = [
        ...(getUserfromSS()?.roleId != 3 ? [{ name: "Organization", key: "requestedBy", },] : []),
        { name: "Request", key: "description", },
        { name: "Requested Date", key: "requestedDate", },
        ...(getUserfromSS()?.roleId != 3 ? [{
            name: "Status", key: "status", selector: (r) => (<div>
                <select name='staus' className='form-select ' value={r?.status} onChange={(e) => updateStatus(r, e.target.value)} style={{ backgroundColor: buttonColor(r?.status), width: "10vw", fontSize: "1vw" }}>
                    {r?.status != "Completed" && r?.status != "Rejected" && <option>Pending</option>}
                    {r?.status != "Rejected" && <option>Completed</option>}
                    {r?.status != "Completed" && <option>Rejected</option>}
                </select>
            </div>)
        },] : [{
            name: "Status", key: "status",
            selector: (row) => <button className=" rounded border border-none" style={{ width: "10vw", fontSize: "1vw", height: "40px", backgroundColor: buttonColor(row?.status) }} >{row?.status}</button>
        }]),
        {
            name: "Action", sortable: false,
            selector: (r) => (<div className="d-flex align-items-center">
                {getUserfromSS()?.roleId == 3 && !["Rejected", "Completed"].some(e => e === r?.status) && <FiEdit className="pointer" title="Edit" size={18}
                    onClick={() => changeModal({ addRequest: true, updatingData: r })} />}

                <div
                    className=" ms-2"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target={`#offcanvasRight-${r.id}`}
                    aria-controls={`offcanvasRight-${r.id}`}
                >
                    <CiStickyNote className="pointer" title="Notes" size={18}
                    // onClick={() => changeModal({ manageRequest: true, updatingData: r })} 
                    />
                </div>

                <div
                    className="offcanvas offcanvas-end"
                    tabIndex={-1}
                    id={`offcanvasRight-${r.id}`}
                    aria-labelledby={`offcanvasRightLabel-${r.id}`}
                >
                    <div className="offcanvas-header">
                        {/* <h5 className="offcanvas-title" id={`offcanvasRightLabel-${r.id}`}>
                            Manage request
                        </h5> */}
                        <Card.Header
                            className=" d-flex justify-content-between align-items-center w-100 text-white"
                            style={{ backgroundColor: "#154360", height: "70px" }}
                        >
                            <div className="p-2">Manage Request</div>

                        </Card.Header>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body">
                        <ManageNotes updatingData={r} />
                    </div>
                </div>
            </div>)
        }
    ]

    const hospitalKeys = [
        {
            name: "Image",
            sortable: false,
            selector: (r) => (
                <div className="d-flex align-items-center gap-2">
                    <label for={`${r?.hospitalId}`} className="d-flex">
                        {r?.["image"] ? <img style={{ height: "50px", width: "50px", borderRadius: "50px" }} src={`${basefileURL}Hospital/${r?.["image"]}`} /> : <RxAvatar size={"50px"} />}
                        < input accept="image/gif, image/jpeg,image/png" onChange={changeHandler(r?.hospitalId, "Hospital")} style={{ display: "none" }} id={`${r?.hospitalId}`} type="file" />
                        <div className="pointer" style={{ position: "relative" }}><TbCameraPlus size={18} color="#666363" title="Upload image" /></div>
                    </label>
                    {/* <div>{r.hospitalName}</div> */}
                </div>
            ),

        },
        { name: "Name", key: "hospitalName" },
        {
            name: "Action", sortable: false,
            selector: (r) => (<div className=" d-flex gap-2"><FiEdit className="pointer" title="Edit" size={18}
                onClick={() => changeModal({ addHospital: true, updatingData: r })}
            />
                <FaStethoscope className="pointer" title="Assign Speciality" size={18}
                    onClick={() => changeModal({ assignSpeciality: true, updatingData: r })}
                />

            </div>),
        }
    ]
    const assignmoduleKey = [
        {
            name:"#OID",
            key: "oid", 
            selector: (row) => <div >{row?.oid}</div>
            
        },
        
        {
            name:"Modules",
            key: "modules", 
            selector: (row) => <div   >{row?.modules}</div>
        },
       
   
        {
            name: "Action",
            selector: (row) => <div  >
              <button  onClick={() => changeModal({ addModule: true })} className='btn btn text-white' style={{backgroundColor:"#154360"}} > Add/Edit Modules</button>
              

            </div>,
          },
    ]

    const organizationKeys = [

        { name: "Organization Name", key: "organizationName", selector: (r) => r?.organizationName, sortable: true },
        { name: "Email", key: "email", selector: (r) => r?.email },
        { name: "Phone", key: "phone", selector: (r) => r?.phone },
        {
            name: "Status",
            key: "status",
            selector: (row) => <div style={{ backgroundColor: "#d8ffec", height: "30px", color: "#36ba73", width: "100px" }} className='rounded d-flex justify-content-center align-items-center  ' >{row?.status}</div>
        },

        {
            name: "Action", sortable: false,
            selector: (r) => (<div style={{ backgroundColor: "#F7F7F7", height: "30px", color: "#154360", width: "130px" }}>
                <FiEdit className="pointer ms-2" title="Edit" size={18}
                onClick={() => changeModal({ addOrganization: true, updatingData: r })}
            />
           {r?.status=="Approve"&& <PiCirclesThreeFill size={20} className="pointer ms-2" title="Assign Modules" onClick={() => changeModal({ addModule: true, updatingData: r })} />}
             <MdLockOutline size={20} className="pointer ms-2" title="" onClick={() => changeModal("addDesignation", r)} />
           <IoEyeSharp size={20} className="pointer ms-2" title="View organization" onClick={() => navigate(`/v1/organization/organization-details/${encryptData(r?.organizationId)}`)} />
            {/* { r?.organizationId} */}
            </div>)
        },
        // {
        //     name: "Action",
        //     selector: (row) => <div style={{backgroundColor:"#F7F7F7",height:"30px",color:"#154360"}} className='rounded d-flex justify-content-around align-items-center '>
        //       <PiCirclesThreeFill size={20} className="pointer" title="Assign Modules" onClick={() => navigate("/v1/organization/assain-module/1")} />
        //       <MdLockOutline size={20} className="pointer" title="Edit Designation" onClick={() => changeModel("addDesignation", row)} />
        //       <IoEyeSharp size={20} className="pointer" title="Edit Designation" onClick={() => navigate("/v1/organization/organization-details/2")} />


        //     </div>,
        //   },

    ]
    const medicalLicenceCertificatesKey=[
        
        { name: "Certificate Name", key: "certificateName", selector: (r) => r?.certificateName, sortable: true },
        { name: "Issue Date", key: "issueDate", selector: (r) =>moment(r?.issueDate).format("DD-MM-YYYY") },
        { name: "ExpiryDate", key: "expiryDate", selector: (r) => moment(r?.expiryDate).format("DD-MM-YYYY") },
        {
            name: "Action",
            key: "status",
            selector: (row) => <div style={{ backgroundColor: "#d8ffec", height: "30px", color: "#36ba73", width: "100px" }} className='rounded d-flex justify-content-center align-items-center  ' > <a
            className="ml-5 pointer"
            target="_blank"
            onClick={() => handleDownload(basefileURL, "MedicalLicense", row.fileName)}
          >
            <FontAwesomeIcon icon={faDownload} className="float-end" size="lg" title="Download" />

          </a>
          
            <a href={`${basefileURL}/MedicalLicense/${row?.fileName}`} target='_blank'>
              <MdOutlineRemoveRedEye color="black" size={25} title="View document" /></a>
          </div>
        },
         
    ]

    const userKeys = [
        {
            name: "Profile",
            sortable: false,
            selector: (r) => (
                <div className="d-flex align-items-center gap-2">
                    <label className="d-flex ">
                        <div className="d-flex" for={`${r?.userId}`}>
                            {r?.["image"] ? <img style={{ height: "48px", width: "48px", borderRadius: "50px" }} src={`${basefileURL}Users/${r?.["image"]}`} /> : <RxAvatar size={"50px"} />}
                            < input accept="image/gif, image/jpeg,image/png" onChange={changeHandler(r?.userId, "User")} style={{ display: "none" }} id={`${r?.userId}`} type="file" />
                            <div className="pointer" style={{ position: "relative" }}><TbCameraPlus size={18} color="#666363" title="Upload image" /></div>
                        </div>

                    </label>

                </div>
            ),

        },
        { name: "User Name", key: "userName" },
        { name: "Email", key: "email" },
        { name: "Role", key: "roleName" },
        ...(getUserfromSS()?.roleId == 1) ?
            [
                { name: "Organization", key: "organizationName" },
                {
                    name: "Action", sortable: false,
                    selector: (r) => (<div className="d-flex gap-2">
                        {r?.roleId == 4 && <FiEdit className="pointer" title="Edit" size={18}
                            onClick={() => changeModal({ addUser: true, updatingData: r })} />}
                        {r?.roleId == 2 && <GoOrganization className="pointer" title="Assign Hospital" size={18}
                            onClick={() => changeModal({ assignOrganization: true, updatingData: r })}
                        />}
                    </div>)
                }
            ] :
            [
                {
                    name: "Action", sortable: false,
                    selector: (r) => (<div className="d-flex gap-2"><FiEdit className="pointer" title="Edit" size={18}
                        onClick={() => changeModal({ addUser: true, updatingData: r })} />
                        {r?.roleId == 4 && <FaStethoscope className="pointer" title="Assign Speciality" size={18}
                            onClick={() => changeModal({ assignSpeciality: true, updatingData: r })}
                        />}
                        {r?.roleId == 4 && <FaRegHospital className="pointer" title="Assign Hospital" size={18}
                            onClick={() => changeModal({ assignHospital: true, updatingData: r })}
                        />}
                        {r?.roleId == 3 && <FaRegHospital className="pointer" title="Assign Hospital" size={18}
                            onClick={() => changeModal({ assignSingleHospital: true, updatingData: r })}
                        />}
                    </div>)
                }
            ]
    ]

    const returnModal = ({ component, key, size }) => <>
        <Modal size={size || "md"} aria-labelledby="contained-modal-title-vcenter" centered show={modal?.[key]} onHide={() => changeModal({ [key]: false })}>
            {component}
        </Modal>
    </>

    return {
        navigate, checkLoginStatus, changeModal, callorUpdateDataList,
        organizationKeys, hospitalKeys, userKeys, requestKeys,medicalLicenceCertificatesKey, returnModal
    };
};