import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { getUserfromSS, returningValue, } from "../../../../ConfigFiles/Config";
import { useFormValidation } from "../../../../Validations/useFormValidations";
import { MdCancel } from "react-icons/md";
import { ConfigFunctions } from "../../../../ConfigFiles/ConfigFunctions";
import { useSelector } from "react-redux";
import moment from "moment";
import "./ManageRequest.css"
const ManageNotes = () => {
    const { changeModal, theme } = ConfigFunctions();
    const updatingData = useSelector(s => s?.updatingData);
    const [isSaving, setIsSaving] = useState(false);

    const submit = async () => {
        // let res = await save(urls?.Organization?.CreateAccount, data);
        // if (res?.data?.status == true) changeModal({ addRequest: false, update: Date.now() });
    };

    const { data, errors, formChange, handleSubmit, setV, addObject } = useFormValidation({
        initialvalues: {},
        validationSchema: "",
        submit: submit,
    });

    useEffect(() => {
        addObject(updatingData);
    }, [updatingData]);
    console.log(updatingData)
    const values = [
        {
            "id": 78,
            "ticketId": 42,
            "notes": "Hi....",
            "addedBy": 1,
            "createdDate": "2024-10-04T11:27:53.413",
            "updatedDate": "2024-10-04T11:27:53.417",
            "addedByName": "Yashoda Hospitals"
        },
        {
            "id": 79,
            "ticketId": 42,
            "notes": "hi....!",
            "addedBy": 2336,
            "createdDate": "2024-10-04T11:28:41.513",
            "updatedDate": "2024-10-04T11:28:41.513",
            "addedByName": "Subhashini"
        },
        {
            "id": 78,
            "ticketId": 42,
            "notes": "How are you",
            "addedBy": 1,
            "createdDate": "2024-10-04T11:27:53.413",
            "updatedDate": "2024-10-04T11:27:53.417",
            "addedByName": "Yashoda Hospitals"
        },
        {
            "id": 79,
            "ticketId": 42,
            "notes": "Fine what about you",
            "addedBy": 2336,
            "createdDate": "2024-10-04T11:28:41.513",
            "updatedDate": "2024-10-04T11:28:41.513",
            "addedByName": "Subhashini"
        },
        {
            "id": 78,
            "ticketId": 42,
            "notes": "i am also fine ",
            "addedBy": 1,
            "createdDate": "2024-10-04T11:27:53.413",
            "updatedDate": "2024-10-04T11:27:53.417",
            "addedByName": "Yashoda Hospitals"
        },
        {
            "id": 79,
            "ticketId": 42,
            "notes": "Hooo great......!",
            "addedBy": 2336,
            "createdDate": "2024-10-04T11:28:41.513",
            "updatedDate": "2024-10-04T11:28:41.513",
            "addedByName": "Subhashini"
        },
        {
            "id": 78,
            "ticketId": 42,
            "notes": "I need some help",
            "addedBy": 1,
            "createdDate": "2024-10-04T11:27:53.413",
            "updatedDate": "2024-10-04T11:27:53.417",
            "addedByName": "Yashoda Hospitals"
        },
        {
            "id": 79,
            "ticketId": 42,
            "notes": "What is that",
            "addedBy": 2336,
            "createdDate": "2024-10-04T11:28:41.513",
            "updatedDate": "2024-10-04T11:28:41.513",
            "addedByName": "Subhashini"
        },
        {
            "id": 78,
            "ticketId": 42,
            "notes": "Can you change my password",
            "addedBy": 1,
            "createdDate": "2024-10-04T11:27:53.413",
            "updatedDate": "2024-10-04T11:27:53.417",
            "addedByName": "Yashoda Hospitals"
        },
        {
            "id": 79,
            "ticketId": 42,
            "notes": "Okey it take time ",
            "addedBy": 2336,
            "createdDate": "2024-10-04T11:28:41.513",
            "updatedDate": "2024-10-04T11:28:41.513",
            "addedByName": "Subhashini"
        }
    ]

    return (
        <Card className="">
           

            <div className="p-4 mb-2 ">
                {values?.length > 0 ? (
                    <div className=" " style={{ maxHeight: "55vh", overflowY: "scroll" }}>
                        {values?.map((e, i) => (
                            <div
                                key={i}
                                className={`message ${e?.addedByName === getUserfromSS()?.firstName ? "sender" : "receiver"}`}
                            >
                                <div>
                                    <div>
                                        <div className={`chat-bubble ${e?.addedByName === getUserfromSS()?.firstName ? "right" : "left"}`}>
                                            <span className="message-text">{e?.notes}</span>
                                            <span className="message-info">
                                                <span className="sender-name">{e?.addedByName}</span>
                                                <small className="timestamp ms-2">
                                                    {moment(e?.createdDate).format("Do MMM h:mm A")}
                                                </small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                ) : (
                    <div className="ms-2 text-center text-danger">No records found</div>
                )}
                {updatingData?.status !== "Completed" && updatingData?.status !== "Rejected" && (
                    <Row className="">
                        <Col lg={12} className="">
                            <Form.Group className="mb-3">
                                <Form.Label className="fw-bold">
                                    Note
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        size="md"
                                        required
                                        type="text"
                                        name="request"
                                        className={`input-heigt rounded-0 ${returningValue(errors?.note, "ErrorColor")}`}
                                        placeholder="Note"
                                        value={returningValue(data?.note, "AlphaNumaric")}
                                        onChange={formChange("AlphaNumaric")}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Button
                                className=" col-lg-12 mt-2 d-flex align-itrems-center justify-content-center"
                                type="submit"
                                // disabled={updatingData?.requestId > 0}
                                onClick={handleSubmit}
                                style={{ border: "none", backgroundColor: "#154360" }}
                            >
                                Save
                            </Button>
                        </Col>

                    </Row>
                )}
            </div>

        </Card>


    );
};
export default ManageNotes;
