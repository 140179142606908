const initalState = {
  update: Date.now(),

};
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "CHANGEMODALSTATE":
      return {
        ...state,
        ...action?.payload,
        modal: action?.payload,
      };
    case "CHANGEDATASTATE":
      return { ...state, ...action?.payload };
    case "CHANGESTATE":
      return {
        ...state,
        ...action?.payload,
      };
    default:
      return state;
  }
};

export { reducer };
